@media (max-width: 1000px) {
    .row>* {
        max-width: None;
    }

    #threads-mapa{
        position: absolute;
        height: 82.6%;
        width: 65%;
        margin-left: 19%;
        margin-top: 16%;
    }

    #map-client-container {
        padding-right: 0;
    }

    #voice-actor-container{
        margin-top: -20%;
        margin-left: 10%;
        width: 70%;
        transform: rotate(1deg);
    }

    #latinafy-container{
        margin-left: 7%;
        margin-top: 5%;
        width: 110%;
        transform: rotate(3.5deg);
    }

    #mccain-publicidad{
        margin-left: 8%;
        margin-top: 20%;
        width: 130%;
        transform: rotate(-2deg);
    }

    #cartoon-network-container{
        margin-left: 18%;
        margin-top: 23%;
        width: 183%;
    }

    #cartoon-network-publicidad{
        transform: rotate(3.5deg);
    }

    #featured-commercial-note-container {
        margin-top: -1%;
        width: 68%;
        margin-left: 12%;
    }

    #featured-commercial-note {
        transform: rotate(15deg);
    }

    #evan-container{
        margin-top: 157%;
        margin-left: -12%;
        width: 85%;
        transform: rotate(-3deg);
    }

    #evan-note-container{
        width: 55%;
        margin-top: 3%;
        margin-left: 5%;
    }

    #fernet-container{
        margin-left: 20%;
        margin-top: 13%;
        width: 90%;
    }

    #fan-club-container {
        margin-top: 77%;
        transform: rotate(1deg);
        width: 170%;
    }

    #milka-publicidad-container{
        width: 90%;
        margin-left: 21%;
    }

    #content-creator-container{
        position: relative;
        margin-top: -12%;
        margin-left: -35%;
        width: 55%;
        margin-bottom: 15%;
    }

    #content-creator{
        transform: rotate(2deg);
    }

    #uala-publicidad{
        margin-top: 158%;
        margin-left: -73%;
        transform: rotate(-3deg);
        width: 143%;
    }

    #sprite-publicidad{
        transform: rotate(0deg);
        margin-top: 10%;
        margin-left: 0;
        width: 130%;
    }

    #artistic-note-continer{
        position: relative;
        margin-top: 95%;
        margin-left: -5%;
        width: 53%;
    }

    #artistic-note{
        transform: rotate(12deg);
    }

    #zamba-container{
        margin-top: 16%;
        margin-left: 7%;
        width: 85%;
    }

    #zamba-note-container{
        width: 46%;
        margin-top: 3%;
        margin-left: 29%;
    }

    #kiko-container{
        margin-top: 10%;
        margin-left: 33%;
        width: 95%;
    }

    #kiko-note-container{
        width: 45%;
        margin-left: 60%;
    }

    #actor-container{
        position: relative;
        width: 47%;
        margin-top: -30%;
    }

    #actor-note{
        transform: rotate(5deg);
    }

    #sound-container{
        width: 51%;
        margin-top: 130%;
        margin-left: 4%;
    }

    #sound-note {
        transform: rotate(-4deg);
    }

    #menudeldia-publicidad{
        transform: rotate(4deg);
        margin-left: -48%;
        width: 115%;
        margin-top: 2%;
    }

    #theo-container{
        margin-top: 8%;
        margin-left: 5%;
        width: 100%;
        transform: rotate(3deg);
    }

    #theo-note-container{
        width: 50%;
        margin-top: -29%;
        margin-left: 102%;
    }

    #danonino-container{
        margin-top: 20%;
        margin-left: -52%;
        width: 140%;
    }
}

@media (max-width: 600px) {

    #threads-mapa{
        height: 83.7%;
        width: 63%;
        margin-left: 23%;
        margin-top: 16%;
    }

    #voice-actor-container{
        margin-top: -20%;
        width: 80%;
    }

    #latinafy-container{
        width: 120%;
    }

    #mccain-publicidad{
        margin-top: 25%;
        width: 140%;
    }

    #cartoon-network-container{
        width: 190%;
    }

    #featured-commercial-note-container {
        margin-top: 6%;
        width: 83%;
    }

    #evan-container{
        margin-top: 158%;
        margin-left: -15%;
        width: 95%;
        transform: rotate(-3deg);
    }

    #evan-note-container{
        width: 65%;
        margin-left: 5%;
    }

    #fernet-container{
        margin-left: 20%;
        margin-top: 21%;
        width: 105%;
    }

    #fan-club-container {
        width: 183%;
        margin-left: 16%;
        margin-top: 79%;
    }

    #milka-publicidad-container{
        width: 105%;
        margin-left: 20%;
    }

    #content-creator-container{
        position: relative;
        margin-top: -8%;
        margin-left: -33%;
        width: 65%;
    }

    #content-creator{
        transform: rotate(2deg);
    }

    #uala-publicidad{
        margin-top: 170%;
        margin-left: -83%;
        transform: rotate(-2deg);
        width: 158%;
    }

    #sprite-publicidad{
        transform: rotate(0deg);
        margin-top: 20%;
        margin-left: 0;
        width: 145%;
    }

    #artistic-note-continer{
        margin-top: 92%;
        width: 64%;
    }

    #zamba-container{
        margin-top: 18%;
        width: 93%;
    }

    #zamba-note-container{
        width: 54%;
        margin-top: 5%;
        margin-left: 25%;
        margin-bottom: 8%;
    }

    #kiko-container{
        margin-top: 10%;
        margin-left: 39%;
        width: 105%;
    }

    #kiko-note-container{
        width: 55%;
        margin-left: 68%;
    }

    #actor-container{
        position: relative;
        width: 58%;
        margin-top: -50%;
        margin-left: -2%;
    }

    #actor-note{
        transform: rotate(5deg);
    }

    #sound-container{
        width: 59%;
        margin-top: 143%;
        margin-left: 10%;
    }

    #menudeldia-publicidad{
        transform: rotate(4deg);
        margin-left: -45%;
        width: 126%;
        margin-top: 9%;
    }

    #theo-container{
        margin-top: 8%;
        width: 115%;
    }

    #theo-note-container{
        width: 60%;
        margin-top: -33%;
    }

    #danonino-container{
        margin-top: 28%;
        margin-left: -45%;
        width: 140%;
    }
}