#landing-view{
    height: 100%;
}

#map-client-container{
    width: 100%;
    height: 40%;
    margin-top: -2%;
    display: block;
    padding-right: 3%;
    padding-bottom: 3%;
    padding-top: 2.5%;
    background-size: cover;
}