.clickable {
    cursor: pointer;
}
.map-container{
    width: 100%;
    position: relative;
}
.relative-container{
    position: relative;
    top: 0;
    left: 0;
}

.black-shadow{
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

#threads-mapa{
    position: absolute;
    height: 82%;
    width: 73%;
    margin-left: 16%;
    margin-top: 1%;
    display: inline;
    z-index: 0;
}
.zoom {
    transition: transform .2s; /* Animation */
}

.zoom-mini {
    transition: transform .2s; /* Animation */
}

@media (hover: hover) {
    /*Solo se ejecuta cuando no es touch*/
    .zoom:hover {
        transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
    .zoom-mini:hover {
        transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
}

#map-anchor{
    display: block;
    position: relative;
    top: -10vh;
    visibility: hidden;
}

/*      PRIMERA COLUMNA       */

#cartoon-network-container{
    margin-left: 38%;
    display: block;
    z-index: 2;
    width: 110%;
    height: 100%;
}

#cartoon-network-publicidad{
    width: 100%;
    transform: rotate(-4deg);
}

#animated-series-note-container{
    position: relative;
    margin-left: 10%;
    margin-top: 10%;
    z-index: 1 !important;
    display: block;
    width: 128%;
    height: 100%;
}

#animated-series-note{
    width: 100%;
    transform: rotate(-5deg);
}

#actor-container{
    position: absolute;
    z-index: 3 !important;
    display: block;
    width: 39%;
    margin-top: 200%;
}

#actor-note{
    transform: rotate(-5deg);
    width: 100%;
}

#danonino-container{
    margin-top: 6%;
    margin-left: 45%;
    z-index: 1;
    display: block;
    width: 93%;
}

#danonino-publicidad{
    width: 100%;
}

#fernet-milka-container{
    margin-left: 20%;
    width: 150%;
    margin-top: 10%;
}

#milka-publicidad-container{
    z-index: 0;
    width: 100%;
    display: block;
}

#milka-publicidad{
    width: 100%;
}

#fernet-container{
    margin-left: -2%;
    z-index: 1;
    display: block;
    width: 100%;
}

#fernet-publicidad{
    width: 100%;
    transform: rotate(-1deg);
}

#fernet-ntoe{
    margin-top: -3%;
    margin-left: 26%;
}

.publi-note-container{
    margin-top: 5%;
    margin-left: 27%;
    width: 48%;
}

.publi-note{
    width: 100%;
}

/*      SEGUNDA COLUMNA       */


#voice-actor-container{
    margin-left: 30%;
    margin-top: 5%;
    width: 50%;
    display: block;
    z-index: 2;
    transform: rotate(3deg);
}

#voice-actor-note{
    width: 100%;
}

#fan-club-container {
    margin-top: 30%;
    margin-left: 25%;
    transform: rotate(-1deg);
    z-index: 10;
}

#fan-club {
    width: 100%;
}

#blueprint-damian{
    margin-left: -0%;
    width: 90%;
}

#blueprint-damian-container{
    margin-top: 12%;
    margin-left: 40%;
    width: 100%;
}

#content-creator-container{
    position: absolute;
    z-index: 4;
    display: block;
    top: 1%;
    left: 25%;
    width: 45%;
}

#content-creator{
    transform: rotate(11deg);
    width: 100%;
}

#zamba-container{
    margin-top: 15%;
    margin-left: 60%;
    width: 55%;
    display: block;
    z-index: 4;
}

#zamba-note-container{
    width: 35%;
    margin-top: 2%;
    margin-left: 70%;
    display: block;
    z-index: 3;
}

#sound-container{
    z-index: 5;
    display: block;
    width: 45%;
    margin-top: 8%;
    margin-left: 58%;
}

#sound-note{
    transform: rotate(2deg);
    width: 100%;
}

/*      TERCERA COLUMNA       */

#latinafy-container{
    margin-left: -34%;
    margin-top: 12%;
    width: 150%;
    display: block;
    z-index: 2;
}

#latinafy-publicidades{
    width: 100%;
}

#featured-commercial-note-container {
    display: block;
    position: relative;
    z-index: 5;
    margin-top: 79%;
    width: 110%;
}

#featured-commercial-note {
    width: 100%;
    transform: rotate(3deg);
}

.personajes-img {
    width: 100%;
}

#kiko-container{
    margin-top: 40%;
    margin-left: 27%;
    width: 55%;
    display: block;
    z-index: 4;
}

#kiko-note-container{
    width: 35%;
    margin-left: 38%;
    display: block;
    z-index: 3;
}

#theo-container{
    margin-top: 10%;
    margin-left: 25%;
    width: 58%;
    display: block;
    z-index: 4;
    transform: rotate(-2deg);
}

#theo-note-container{
    width: 38%;
    margin-top: 2%;
    margin-left: 35%;
    display: block;
    z-index: 3;
}

#evan-container{
    margin-top: 10%;
    margin-left: 31%;
    width: 55%;
    display: block;
    z-index: 4;
    transform: rotate(3deg);
}

#evan-note-container{
    width: 35%;
    margin-top: 1%;
    margin-left: 38%;
    display: block;
    z-index: 3;
}

#menudeldia-publicidad{
    transform: rotate(-4deg);
    margin-left: -15%;
    margin-top: 25%;
    width: 75%;
    display: block;
    z-index: 3;
}

/*      CUARTA COLUMNA       */

.fotos-publicidades-mapa-container {
    margin-top: 4%;
    margin-left: -20%;
    -webkit-filter: drop-shadow(3px 3px 5px #000);
    filter: drop-shadow(3px 3px 5px #000);
}

#uala-publicidad{
    margin-top: -2%;
    margin-left: -13%;
    transform: rotate(3deg);
    display: block;
    z-index: 2;
    width: 80%;
}

#mccain-publicidad{
    margin-left: -8%;
    margin-top: 3%;
    display: block;
    z-index: 2;
    width: 85%;
}

#sprite-publicidad{
    transform: rotate(-1deg);
    margin-top: 5%;
    margin-left: -15%;
    width: 83%;
    display: block;
    z-index: 2;
}

#tvSeries-notas{
    position: relative;
    margin-left: -15%;
    top: 23%;
    display: block;
    z-index: 1;
    width: 120%;
}

#tvSeries-notas-img{
    width: 100%;
    transform: rotate(2deg);
}

#artistic-note-continer{
    position: absolute;
    top: 1%;
    left: 60%;
    z-index: 2;
    width: 40%;
}

#artistic-note{
    width: 100%;
    transform: rotate(16deg);
}

.fotos-publicidades-mapa {
    width: 120%;
}

