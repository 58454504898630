
#cassete-comp-container{
    width: 100%;
    height: 100%;
}
.cassete-img-container{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.cassete-button-container{
    position: absolute;
    top: -4%;
    left: -2%;
    width: 102%;
    height: 102%;
    padding-left: calc(var(--bs-gutter-x) * .5);
}
.cassete-button{
    display: none;
}

.square-cassete{
    position: absolute;
    height: 2.5vw;
    width: 2.3vw;
    top: 78.5%;
    display: block;
    z-index: 8;
    cursor: pointer;
}

.square-play{
    left: 32%;
}

.square-stop{
    left: 41.5%;
}

.square-replay{
    left: 51%;
}

.square-download{
    left: 60%;
}

@media (max-width: 1400px) {
    .cassete-button-container{
        top: -5%;
    }

    .img-download, .img-replay, .img-stop {
        margin-left: -1%;
    }
}

@media (max-width: 1000px) {
    .square-cassete {
        height: 20%;
        width: 10.5%;
        top: 100%;
    }

    .cassete-container {
        padding: 5% 0 0 !important;
    }

    .cassete-button-container{
        top: -26%;
        left: -14.9%;
        width: 130%;
    }

    .img-stop {
        margin-left: 0%;
    }

}

@media (max-width: 600px) {
    .square-cassete {
        height: 22%;
    }

    .cassete-button-container{
        top: -24%;
        left: -16.9%;
        width: 130%;
    }

    .img-download, .img-replay, .img-stop {
        margin-left: 0;
    }
}