.wanted-container{
    margin-top: -2%;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    z-index: 0;
}

#contacto-anchor{
    display: block;
    position: relative;
    visibility: hidden;
}

#wanted-image{
    width: 100%;
    transform: rotate(-1deg);
}

#wanted-container{
    padding: 5%;
    width: 47%;
}

#followers-container{
    position: absolute;
    top: 19%;
    left: 19%;
    width: 60%;
    transform: rotate(-1deg);
}

#contact-container{
    position: absolute;
    top: 81%;
    left: 19%;
    width: 60%;
    transform: rotate(-1deg);
}

.square-wanted{
    position: absolute;
    height: 2vw;
    width: 2vw;
    top: 50%;
    left: 12vw;
    display: block;
    z-index: 8;
    cursor: pointer;
}

#square-youtube{
    left: 51%;
}

#square-tiktok{
    left: 42.5%;
}

#square-instagram{
    left: 34%;
}

@media (max-width: 1000px) {
    #wanted-container{
        padding: 20% 0;
        width: 96%;
    }

    #followers-container{
        top: 22%;
    }

    #contact-container{
        top: 80%;
    }

    .square-wanted{
        height: 35%;
        width: 8%;
    }

    #square-youtube{
        top: 50%;
        left: 51%;
    }

    #square-tiktok{
        top: 50%;
        left: 42.5%;
    }

    #square-instagram{
        top: 50%;
        left: 34%;
    }
}