.clients-container{
    margin-top: 2%;
    width: 100%;
    position: relative;
}

#clients-title-container{
    position: absolute;
    width: 12%;
    transform: rotate(-6deg);
    display: inline;
    z-index: 2;
    margin-left: 2.5%;
    margin-top: -3%;
}

#clients-title-img {
    width: 100%;
}

#clients-container{
    margin-top: 5%;
    margin-left: 7%;
    width: 88%;
}

.clients-image {
    width: 110%;
    cursor: pointer;
}

#dada-client {
    margin-top: 40%;
    /*transform: rotate(-3deg);*/
    transition: transform .2s;
}

#cn-client {
    margin-top: 20%;
}

@media (max-width: 1000px) {
    .clients-container{
        margin-top: 15%;
        width: 100%;
        position: relative;
        padding-bottom: 6%;
    }

    #clients-title-container{
        position: absolute;
        width: 25%;
        transform: rotate(6deg);
        display: inline;
        z-index: 2;
        margin-left: 6%;
        margin-top: -12%;
    }

    #dada-client {
        margin-top: 20%;
        transform: rotate(-3deg);
    }

    #clients-container{
        margin-left: 3%;
        width: 94%;
    }
}