
.modal {
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    border: none;
    background-color: transparent;
}

.modal-body {
    padding: unset;
    height: 70vh;
}

.close-arrow{
    position: absolute;
    top: -10%;
    right: -21%;
    cursor: pointer;
}

.click-left-arrow, .click-right-arrow {
    position: absolute;
    top: 50%;
}

.click-left-arrow {
    left: -10%;
}

.click-right-arrow {
    right: -10%;
    transform: rotate(180deg);
}

@media (orientation: portrait) {
    .modal-dialog {
        margin: 0;
        max-width: none;
    }
    .modal-content {
        height: auto;
        background-color: transparent;
    }

    .modal-body {
        padding: unset;
        max-height: 75vw !important;
        /*height:  !important;*/
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1400px) {
    .custom-modal{
        max-width: 100vh !important;
    }

    .close-arrow{
        right: -38%;
    }
}


@media (max-width: 1000px) {
    .close-arrow{
        top: -10%;
        right: -30%;
    }

    .click-left-arrow, .click-right-arrow {
        top: 115%;
    }

    .click-left-arrow {
        left: 20%;
    }

    .click-right-arrow {
        right: 20%;
    }
}

@media (max-width: 700px) {
    .close-arrow{
        top: -15%;
        right: -60%;
    }
}