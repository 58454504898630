.cassete-container{
    width: 100%;
    padding: 3% 8% 3%;
    background-size:100% 100%;
    background-repeat: no-repeat;
    display: block;
    z-index: 1;
}

.cassete-for-all{
    width: 90%;
}

#demos-title{
    width: 45%;
    display: block;
    margin-left: 32%;
    margin-bottom: 3%;
}

#cassete-anchor {
    display: block;
    position: relative;
    top: -10vh;
    visibility: hidden;
}

@media (max-width: 1000px) {
    #demos-title{
        width: 65%;
        display: block;
        margin-left: 16%;
        margin-bottom: 0;
        margin-top: 12%;
    }

    #click-top-play-cassete{
        width: 38%;
        margin-left: 5%;
    }

    .cassete-container{
        padding: 0;
        padding-bottom: 8% !important; ;
    }

    .cassetes-mobile-container{
        margin-top: -4%;
    }
}