.slider-container{
    height: 100%;
    width: 65vh;
}

.carousel-item-custom{
    padding: 5%;
}

.carousel-indicators [data-bs-target] {
    width: 10px; /* change width */
    height: 10px; /* change height */
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    /*background-color: #fff;*/
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: opacity .6s ease;
    border-radius: 100%; /* add border-radius */
}

.carousel-image{
    transform: rotate(5deg);
    -webkit-filter: drop-shadow(5px 5px 5px #642F00);
    filter: drop-shadow(-3px 5px 6px #642F00);
    /*width: 105% !important;*/
}

#left-arrow{
    margin-right: 100%;
}

#right-arrow{
    margin-left: 100%;
}

.arrow-carousel{
    width: 60%;
}

#button-download-crazy-notes, #button-download-songs,
#button-replay-crazy-notes, #button-replay-songs {
    margin-left: -1%;
}

@media (max-width: 1000px ) {
    .slider-container{
        width: 109%;
        margin-left: -6%;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
    }

    .carousel-item-custom{
        margin-top: 4%;
        margin-bottom: 4%;
    }

    #left-arrow{
        margin-right: -30%;
    }

    #right-arrow{
        margin-left: 0;
    }

    .cassete-arrow {
        filter: invert(1);
    }

    .carousel-inner {
        width: 200% !important;
        left: -47% !important;
    }
    .carousel-item-custom{
        padding: 0%;
    }

    .carousel-image{
        transform: rotate(0deg);
        -webkit-filter: none;
        filter: none;
    }

    .carousel-indicators{
        margin-bottom: 0;
    }

    #button-download-crazy-notes, #button-download-songs,
    #button-replay-crazy-notes, #button-replay-songs {
        margin-left: 0%;
    }

    .show-neighbors {
        overflow: hidden;
    }
    .show-neighbors .item__third:first-child,
    .show-neighbors .item__third:last-child {
        display: none;
    }
    .show-neighbors .carousel-indicators {
        margin-right: 25%;
        margin-left: 25%;
    }
    .show-neighbors .carousel-control-start,
    .show-neighbors .carousel-control-end {
        background: rgba(255, 255, 255, 0.3);
        width: 25%;
        z-index: 11;
    }
    .show-neighbors .carousel-inner {
        width: 150%;
        left: -25%;
    }
    .show-neighbors .carousel-item-next:not(.carousel-item-start),
    .show-neighbors .carousel-item-end.active {
        -webkit-transform: translate3d(32.9%, 0, 0);
        transform: translate3d(32.9%, 0, 0);
    }

    .show-neighbors .carousel-item-prev:not(.carousel-item-end),
    .show-neighbors .carousel-item-start.active {
        -webkit-transform: translate3d(-32.9%, 0, 0);
        transform: translate3d(-32.9%, 0, 0);
    }
    .show-neighbors .item__third {
        display: block !important;
        float: left;
        position: relative;
        /* captions can now be added */
        width: 33%;
    }

    .margin-cassete{
        width: 32% !important;
        margin-left: 1%;
    }
}